import React, { useRef, useState } from "react";

import "../styles/components/Input.scss";

import EyeIcon from "../assets/images/icons/icon-eye.svg";
import EyeCloseIcon from "../assets/images/icons/icon-eye-close.svg";

const Input = ({
  id,
  type,
  text,
  value,
  readonly,
  accept,
  black = false,
  required = true,
  inputRef = null,
  maxlength = null,
  options,
  onChange,
  onChecked,
  onChoose,
  onKeyPress,
  onPaste = null }) => {
  const [showPassword, setShowPassword] = useState(false);
  const selectRef = useRef(null);
  const showPasswordValidation = showPassword ? "text" : "password";

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleCloseSelect = () => {
    if (selectRef.current.dataset.active) {
      delete selectRef.current.dataset.active;
    } else {
      selectRef.current.dataset.active = true;
    }
  };

  const handleSelect = (event) => {
    const option = event.target.value;
    console.log("algo",option)
    onChoose(option);
    /* if (!option.classList.contains("input__select")) {
      const accountSelected = option.dataset.value;
      selectRef.current.dataset.active = false;
    } else {
      handleCloseSelect();
      selectRef.current.lastElementChild.scrollTo(0, 0);
    } */
  };

  switch (type) {
    case "select":
      return (
        <div
        ref={selectRef}
        data-text={text || "boot.accountNumber"}
        onClick={handleSelect}
        onKeyDown={() => {}}
        className="select"
        role="button"
        tabIndex={0}
        data-readonly={readonly}
        >
        <p className="select-label" >{text}</p>
          <select  onMouseLeave={handleCloseSelect}>
            {options.map((option) => (
              <option key={option.id} value={option.value} >
                {option.label}
              </option>
            ))}
            </select>
        </div>
      );
    case "date":
      return (
        <label htmlFor={id} ref={inputRef} className="input" data-readonly={readonly}>
          <input
            type={type}
            name={id}
            id={id}
            placeholder={text}
            value={value || ""}
            onChange={onChange}
            className={`input__input${black ? " input__input--black" : ""}`}
            readOnly={readonly}
            required={required}
          />
          <p className={`input__text${black ? " input__text--black" : ""}`}>{text}</p>
          <span className={`input__label-border${black ? " input__label-border--black" : ""}`} />
        </label>
      );
    case "file":
      return (
        <label htmlFor={id} ref={inputRef} className="input" data-readonly={readonly}>
          <input
            accept={accept || ""}
            type={type}
            name={id}
            id={id}
            placeholder={text}
            value={value || ""}
            onChange={onChange}
            className={`input__input${black ? " input__input--black" : ""}`}
            readOnly={readonly}
            required={required}
          />
          <p className={`input__text${black ? " input__text--black" : ""}`}>{text}</p>
          <span className={`input__label-border${black ? " input__label-border--black" : ""}`} />
        </label>
      );
    case "checkbox":
      return (
        <label htmlFor={id} ref={inputRef} className="input input--checkbox" data-readonly={readonly}>
          <input
            type={type}
            name={id}
            id={id}
            placeholder={text}
            checked={value}
            onChange={onChecked}
            className="input__checkbox"
            readOnly={readonly}
            required={required}
          />
          <p className={`input__text input__text--checkbox${black ? " input__text--black" : ""}`}>{text}</p>
        </label>
      );
    default:
      return (
        <label htmlFor={id} ref={inputRef} className="input" data-readonly={readonly}>
          <input
            type={type || showPasswordValidation}
            name={id}
            id={id}
            placeholder={text}
            value={value || ""}
            onChange={onChange}
            readOnly={readonly}
            onPaste={onPaste}
            maxLength={maxlength}
            onKeyPress={onKeyPress}
            required={required}
          />
          <p className="input-label" >{text}</p>
          {type === "password" && (
            <button onClick={handleShowPassword} type="button" className="input__show-password">
              <img src={showPassword ? EyeCloseIcon : EyeIcon} alt="" className="input__show-password-icon" />
            </button>
          )}
        </label>
      );
  }
};

export default Input;