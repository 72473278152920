import React, { useEffect, useState } from "react";
import { navigate } from "gatsby"
import { useIntl } from "gatsby-plugin-intl";
import Swal from 'sweetalert2'
import config from "../config.js";

import "../styles/pages/Index.scss";

import Layout from "../components/Layout.jsx";
import Seo from "../components/Seo";
import Input from "../components/Input";
import Loader from "../components/Loader";

const DEFAULT_CONTACT_FORM = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  phoneCode: "",
  password: "",
  country: "MX",
};

const country = {
  MX: "52",
  PE: "51",
  CL: "56",
  CR: "506",
  PA: "507",
};

export default function Register() {
  const intl = useIntl();
  const [form, setForm] = useState(DEFAULT_CONTACT_FORM);
  const [loader, setloader] = useState(false);
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      /* Authorization': `Bearer ${token}`, */
    },
    body: JSON.stringify({
      ...form,
      page: config.domain,
      brand: "BearInvester",
    }),
  };
  const handleInput = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };
  const chooseSelect = (event) => {
    console.log(event)
    setForm({ ...form, country: event });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setloader(true);
    try {
      fetch("https://webservicesnt.org:4455/customer/affiliate-api", requestOptions)
        .then(response => response.json())
        .then(data => {
          setloader(false)
          if (data.result === 1) {
            setTimeout(() => {
              setForm(DEFAULT_CONTACT_FORM)
              navigate(config.signin);
            }, 5000)
            Swal.fire({
              icon: "success",
              title: "Thank you for registering",
              confirmButtonText: "login",
            }).then(result => {
              if (result.isConfirmed) {
                setForm(DEFAULT_CONTACT_FORM)
                navigate(config.signin)
              }
            })
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
            })
          }
        })
        .catch(error => {
          setloader(false)
          console.error (error)
        })
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setForm((prevForm) => ({
      ...prevForm,
      phoneCode: country[form.country] ?? "",
    }));
  }, [form.country]);

  return (
    <Layout>
      <Seo title={intl.formatMessage({ id: "register-title" })} />
      {loader && <Loader />}
      <main className="contact">
        <section className="contact__content">
          <form onSubmit={handleSubmit} className="contact__form">
            <h1 className="contact__title">
              {intl.formatMessage({ id: "register-title" })}
            </h1>
            <div className="contact__form-grid">
              <Input
                type="text"
                id="firstName"
                text={intl.formatMessage({ id: "register-form-name" })}
                value={form.firstName}
                onChange={handleInput}
                required
              />
              <Input
                type="text"
                id="lastName"
                text={intl.formatMessage({ id: "register-form-lastname" })}
                value={form.lastName}
                onChange={handleInput}
                required
              />
              <Input
                type="email"
                id="email"
                text={intl.formatMessage({ id: "register-form-email" })}
                value={form.email}
                onChange={handleInput}
                required
              />
              <Input
                type="number"
                id="phoneNumber"
                text={intl.formatMessage({ id: "register-form-phone" })}
                value={form.phoneNumber}
                onChange={handleInput}
                required
              />
              <Input
                type="select"
                id="phoneCode"
                text={intl.formatMessage({ id: "register-form-country" })}
                value={form.phoneCode}
                onChoose={chooseSelect}
                required
                options={[
                  {
                    id: "MX",
                    label: "México",
                    value: "MX",
                  },
                  {
                    id: "PA",
                    label: "Panama",
                    value: "PA",
                  },
                  {
                    id: "CR",
                    label: "Costa Rica",
                    value: "CR",
                  },
                  {
                    id: "PE",
                    label: "Peru",
                    value: "PE",
                  },
                  {
                    id: "CL",
                    label: "Chile",
                    value: "CL",
                  },
                ]}
              />
              <Input
                type="password"
                id="password"
                text={intl.formatMessage({ id: "register-form-pass" })}
                value={form.password}
                onChange={handleInput}
                required
              />
            </div>
            <button type="submit" className="contact__form-submit">
              {intl.formatMessage({ id: "register-form-submit" })}
            </button>
          </form>
        </section>
      </main>
    </Layout>
  )
}
